export enum QueryType {
  TOKEN = "TOKEN",
  VAULT_TOKEN = "VAULT_TOKEN",
  PAIR = "PAIR",
  PAIR_PRICES = "PAIR_PRICES",
  PAIR_OPEN_INTEREST = "PAIR_OPEN_INTEREST",
  OPTION_BORROW_RATES = "OPTION_BORROW_RATES",
  ASSET_PRICES = "ASSET_PRICES",
  VAULT = "VAULT",
  VAULT_API_DATA = "VAULT_API_DATA",
  REWARD_TRACKER_DATA = "REWARD_TRACKER_DATA",
  POSITIONS = "POSITIONS",
  HISTORY = "HISTORY",
  PUBLIC_SALE_DATA = "PUBLIC_SALE_DATA",
  LOCK_DATA = "LOCK_DATA",
  AIRDROP_DATA = "AIRDROP_DATA",
  REFERRAL_DATA = "REFERRAL_DATA",
  LEADERBOARD_DATA = "LEADERBOARD_DATA",
}
