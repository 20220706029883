import { Button } from "@chakra-ui/react";
import { useCallback } from "react";

import { TransactionErrorMainButton } from "../../form-components/components/TransactionErrorMainButton";
import { toTokenAmount } from "../../input-card/helpers/tokenAmount";
import { toBig, getZero } from "../../shared/helpers/bigjs";
import { isPositionSideLong } from "../../trade-panel/helpers/isPositionSideLong";
import { useTradePanelOptionPrice } from "../../trade-panel/hooks/useTradePanelOptionPrice";
import { useTradePanelQueries } from "../../trade-panel/hooks/useTradePanelQueries";
import { useTradePanelStrikePrice } from "../../trade-panel/hooks/useTradePanelStrikePrice";
import { useOpenFixedPositionModalTransactions } from "../stores/useOpenFixedPositionModalTransactions";
import { useOpenPositionModalState } from "../stores/useOpenPositionModalState";

export const OpenFixedPositionButton = () => {
  const { quoteTokenInputState, pairId, expiry, positionSide } =
    useOpenPositionModalState();

  const { baseTokenQuery, quoteTokenQuery } = useTradePanelQueries(pairId);
  const { openFixedPositionTransaction } =
    useOpenFixedPositionModalTransactions();

  const strikePrice = useTradePanelStrikePrice(positionSide, pairId) ?? 0;
  const optionPrice = useTradePanelOptionPrice(
    positionSide,
    pairId,
    quoteTokenInputState,
    expiry
  );

  const { mutation, resetTransaction, runTransaction } =
    openFixedPositionTransaction;

  const isLong = isPositionSideLong(positionSide);

  const positionSize = quoteTokenInputState.inputValueBig;
  const notionalBaseSize =
    positionSize.gt(0) && optionPrice && toBig(optionPrice).gt(0)
      ? positionSize.div(optionPrice)
      : getZero();
  const notionalQuoteSize =
    strikePrice && toBig(strikePrice).gt(0)
      ? notionalBaseSize.mul(strikePrice)
      : getZero();

  const baseToken = baseTokenQuery.data;
  const quoteToken = quoteTokenQuery.data;

  const handleButtonClick = useCallback(() => {
    if (baseToken && quoteToken) {
      const notionalAmount = isLong
        ? toTokenAmount(notionalBaseSize, baseToken)
        : toTokenAmount(notionalQuoteSize, quoteToken);

      runTransaction(
        isLong,
        toBig(strikePrice).mul(1e8).toString(),
        notionalAmount.toString(),
        expiry.toString()
      );
    }
  }, [
    baseToken,
    quoteToken,
    isLong,
    expiry,
    runTransaction,
    notionalBaseSize,
    notionalQuoteSize,
    strikePrice,
  ]);

  const title = "Open Position";
  const loadingTitle = "Opening Position...";

  const { isError, isLoading } = mutation;

  if (isError) {
    return <TransactionErrorMainButton resetTransaction={resetTransaction} />;
  }

  return (
    <Button
      isLoading={isLoading}
      loadingText={loadingTitle}
      onClick={handleButtonClick}
      variant={isLong ? "long" : "short"}
    >
      {title}
    </Button>
  );
};
