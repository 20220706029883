import {
  getFormattedBorrowRate,
  getFormattedPrice,
  getFormattedRunway,
} from "../../shared/helpers/formatters";
import {
  Container,
  InfoRow,
  InfoTitle,
  InfoValue,
} from "../../shared/modal/styles/ModalInfo";
import { useTradePanelOptionPrice } from "../../trade-panel/hooks/useTradePanelOptionPrice";
import { useTradePanelStrikePrice } from "../../trade-panel/hooks/useTradePanelStrikePrice";
import { useOpenPositionModalState } from "../stores/useOpenPositionModalState";

export const StrikePriceInfo = () => {
  const { positionSide, pairId, quoteTokenInputState, expiry } =
    useOpenPositionModalState();

  const strikePrice = useTradePanelStrikePrice(positionSide, pairId);
  const optionHourlyBorrowRate = useTradePanelOptionPrice(
    positionSide,
    pairId,
    quoteTokenInputState,
    expiry
  );

  const formattedStrikePrice = getFormattedPrice(strikePrice);
  const formattedHourlyBorrowRate = getFormattedBorrowRate(
    optionHourlyBorrowRate
  );
  const formattedExpiry = getFormattedRunway(expiry);

  return (
    <Container>
      <InfoRow>
        <InfoTitle>Activation Price</InfoTitle>
        <InfoValue>{formattedStrikePrice}</InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoTitle>Funding / 1h</InfoTitle>
        <InfoValue>{formattedHourlyBorrowRate}</InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoTitle>Expiry</InfoTitle>
        <InfoValue>{formattedExpiry}</InfoValue>
      </InfoRow>
    </Container>
  );
};
