import { validateWCInitOptions } from './validation.js';
import walletConnect from './walletConnect.js';
export const isHexString = (value) => {
    if (typeof value !== 'string' || !value.match(/^0x[0-9A-Fa-f]*$/)) {
        return false;
    }
    return true;
};
function initWalletConnect(options) {
    if (!options) {
        throw new Error(`WalletConnect requires an initialization object to be passed - see the official docs for an example: https://onboard.blocknative.com/docs/wallets/walletconnect`);
    }
    if (options) {
        const error = validateWCInitOptions(options);
        if (error) {
            throw error;
        }
    }
    return walletConnect(options);
}
export default initWalletConnect;
