import { networks, ChainId } from '../constants/dist/0xsequence-network-constants.esm.js';
export { ChainId, NetworkType, networks } from '../constants/dist/0xsequence-network-constants.esm.js';
import { ethers, providers } from 'ethers';
import { isBigNumberish, logger } from '@0xsequence/utils';

function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}

function isNetworkConfig(cand) {
  return cand && cand.chainId !== undefined && cand.name !== undefined && cand.rpcUrl !== undefined && cand.relayer !== undefined;
}
const getChainId = chainId => {
  if (typeof chainId === 'number') {
    return chainId;
  }
  if (chainId.chainId) {
    return chainId.chainId;
  }
  return ethers.BigNumber.from(chainId).toNumber();
};
const maybeChainId = chainId => {
  if (!chainId) return undefined;
  return getChainId(chainId);
};
const isValidNetworkConfig = (networkConfig, raise = false, skipRelayerCheck = false) => {
  if (!networkConfig) throw new Error(`invalid network config: empty config`);
  const configs = [];
  if (Array.isArray(networkConfig)) {
    configs.push(...networkConfig);
  } else {
    configs.push(networkConfig);
  }
  if (configs.length === 0) {
    if (raise) throw new Error(`invalid network config: empty config`);
    return false;
  }

  // Ensure distinct chainId configs
  const chainIds = configs.map(c => c.chainId).sort();
  const dupes = chainIds.filter((c, i) => chainIds.indexOf(c) !== i);
  if (dupes.length > 0) {
    if (raise) throw new Error(`invalid network config: duplicate chainIds ${dupes}`);
    return false;
  }

  // Downcase all network names
  configs.forEach(c => c.name = c.name.toLowerCase());

  // Ensure distinct network names
  const names = configs.map(c => c.name).sort();
  const nameDupes = names.filter((c, i) => names.indexOf(c) !== i);
  if (nameDupes.length > 0) {
    if (raise) throw new Error(`invalid network config: duplicate network names ${nameDupes}`);
    return false;
  }

  // Ensure rpcUrl or provider is specified
  // Ensure relayerUrl or relayer is specified
  // Ensure one default chain
  // Ensure one auth chain
  let defaultChain = false;
  for (let i = 0; i < configs.length; i++) {
    const c = configs[i];
    if ((!c.rpcUrl || c.rpcUrl === '') && !c.provider) {
      if (raise) throw new Error(`invalid network config for chainId ${c.chainId}: rpcUrl or provider must be provided`);
      return false;
    }
    if (!skipRelayerCheck) {
      if (!c.relayer) {
        if (raise) throw new Error(`invalid network config for chainId ${c.chainId}: relayer must be provided`);
        return false;
      }
    }
    if (c.isDefaultChain) {
      if (defaultChain) {
        if (raise) throw new Error(`invalid network config for chainId ${c.chainId}: DefaultChain is already set by another config`);
        return false;
      }
      defaultChain = true;
    }
  }
  if (!defaultChain) {
    if (raise) throw new Error(`invalid network config: DefaultChain must be set`);
    return false;
  }
  return true;
};
const ensureValidNetworks = (networks, skipRelayerCheck = false) => {
  isValidNetworkConfig(networks, true, skipRelayerCheck);
  return networks;
};
const ensureUniqueNetworks = (networks, raise = true) => {
  const chainIds = networks.map(c => c.chainId).sort();
  const dupes = chainIds.filter((c, i) => chainIds.indexOf(c) !== i);
  if (dupes.length > 0) {
    if (raise) throw new Error(`invalid network config: duplicate chainIds ${dupes}`);
    return false;
  }
  return true;
};
const updateNetworkConfig = (src, dest) => {
  if (!src || !dest) return;
  if (!src.chainId && !src.name) {
    throw new Error('failed to update network config: source config is missing chainId or name');
  }
  if (src.chainId !== dest.chainId && src.name !== dest.name) {
    throw new Error('failed to update network config: one of chainId or name must match');
  }
  if (src.rpcUrl) {
    dest.rpcUrl = src.rpcUrl;
    dest.provider = undefined;
  }
  if (src.provider) {
    dest.provider = src.provider;
  }
  if (src.relayer) {
    dest.relayer = src.relayer;
  }
};
const validateAndSortNetworks = networks => {
  return ensureValidNetworks(sortNetworks(networks));
};
const findNetworkConfig = (networks, chainId) => {
  if (typeof chainId === 'string') {
    if (chainId.startsWith('0x')) {
      const id = ethers.BigNumber.from(chainId).toNumber();
      return networks.find(n => n.chainId === id);
    } else {
      return networks.find(n => n.name === chainId || `${n.chainId}` === chainId);
    }
  } else if (typeof chainId === 'number') {
    return networks.find(n => n.chainId === chainId);
  } else if (chainId.chainId) {
    return networks.find(n => n.chainId === chainId.chainId);
  } else if (ethers.BigNumber.isBigNumber(chainId)) {
    const id = chainId.toNumber();
    return networks.find(n => n.chainId === id);
  } else {
    return undefined;
  }
};
const checkNetworkConfig = (network, chainId) => {
  if (!network) return false;
  if (network.name === chainId) return true;
  if (network.chainId === chainId) return true;
  return false;
};
const networksIndex = networks => {
  const index = {};
  for (let i = 0; i < networks.length; i++) {
    index[networks[i].name] = networks[i];
  }
  return index;
};

// TODO: we should remove sortNetworks in the future but this is a breaking change for dapp integrations on older versions <-> wallet
// sortNetworks orders the network config list by: defaultChain, authChain, ..rest by chainId ascending numbers
const sortNetworks = networks => {
  if (!networks) {
    return [];
  }
  const config = networks.sort((a, b) => {
    if (a.chainId === b.chainId) return 0;
    return a.chainId < b.chainId ? -1 : 1;
  });

  // DefaultChain goes first
  const defaultConfigIdx = config.findIndex(c => c.isDefaultChain);
  if (defaultConfigIdx > 0) config.splice(0, 0, config.splice(defaultConfigIdx, 1)[0]);
  return config;
};
const stringTemplate = (sTemplate, mData) => {
  if (typeof sTemplate === 'string') {
    mData = mData ? mData : {};
    return sTemplate.replace(/\$\{\s*([$#@\-\d\w]+)\s*\}/gim, function (fullMath, grp) {
      let val = mData[grp];
      if (typeof val === 'function') {
        val = val();
      } else if (val === null || val === undefined) {
        val = '';
      } else if (typeof val === 'object' || typeof val === 'symbol') {
        val = val.toString();
      } else {
        val = val.valueOf();
      }
      return val;
    });
  }
  return '';
};

const indexerURL = network => stringTemplate('https://${network}-indexer.sequence.app', {
  network
});
const relayerURL = network => stringTemplate('https://${network}-relayer.sequence.app', {
  network
});
const nodesURL = network => stringTemplate('https://nodes.sequence.app/${network}', {
  network
});
function findSupportedNetwork(chainIdOrName) {
  return findNetworkConfig(allNetworks, chainIdOrName);
}
function toChainIdNumber(chainIdLike) {
  if (ethers.BigNumber.isBigNumber(chainIdLike)) {
    return chainIdLike;
  }
  if (isBigNumberish(chainIdLike)) {
    return ethers.BigNumber.from(chainIdLike);
  }
  return ethers.BigNumber.from(chainIdLike.chainId);
}
const genUrls = network => {
  const rpcUrl = nodesURL(network);
  return {
    rpcUrl,
    relayer: {
      url: relayerURL(network),
      provider: {
        url: rpcUrl
      }
    },
    indexerUrl: indexerURL(network)
  };
};
const allNetworks = validateAndSortNetworks([_extends({}, networks[ChainId.POLYGON], genUrls('polygon'), {
  isDefaultChain: true,
  isAuthChain: true
}), _extends({}, networks[ChainId.MAINNET], genUrls('mainnet')), _extends({}, networks[ChainId.BSC], genUrls('bsc')), _extends({}, networks[ChainId.AVALANCHE], genUrls('avalanche')), _extends({}, networks[ChainId.ARBITRUM], genUrls('arbitrum')), _extends({}, networks[ChainId.ARBITRUM_NOVA], genUrls('arbitrum-nova')), _extends({}, networks[ChainId.OPTIMISM], genUrls('optimism')), _extends({}, networks[ChainId.OPTIMISM_SEPOLIA], genUrls('optimism-sepolia')), _extends({}, networks[ChainId.POLYGON_ZKEVM], genUrls('polygon-zkevm')), _extends({}, networks[ChainId.GNOSIS], genUrls('gnosis')), _extends({}, networks[ChainId.RINKEBY], genUrls('rinkeby'), {
  disabled: true
}), _extends({}, networks[ChainId.GOERLI], genUrls('goerli'), {
  disabled: true
}), _extends({}, networks[ChainId.SEPOLIA], genUrls('sepolia')), _extends({}, networks[ChainId.POLYGON_MUMBAI], genUrls('mumbai')), _extends({}, networks[ChainId.BSC_TESTNET], genUrls('bsc-testnet')), _extends({}, networks[ChainId.ARBITRUM_SEPOLIA], genUrls('arbitrum-sepolia')), _extends({}, networks[ChainId.BASE], genUrls('base')), _extends({}, networks[ChainId.BASE_SEPOLIA], genUrls('base-sepolia')), _extends({}, networks[ChainId.HOMEVERSE_TESTNET], genUrls('homeverse-testnet')), _extends({}, networks[ChainId.AVALANCHE_TESTNET], genUrls('avalanche-testnet')), _extends({}, networks[ChainId.HARDHAT], {
  rpcUrl: 'http://localhost:8545',
  relayer: {
    url: 'http://localhost:3000',
    provider: {
      url: 'http://localhost:8545'
    }
  }
}), _extends({}, networks[ChainId.HARDHAT_2], {
  rpcUrl: 'http://localhost:9545',
  relayer: {
    url: 'http://localhost:3000',
    provider: {
      url: 'http://localhost:9545'
    }
  }
})]);

const JsonRpcVersion = '2.0';

// EIP-1193 function signature

class JsonRpcRouter {
  constructor(middlewares, sender) {
    this.sender = sender;
    if (middlewares) {
      this.setMiddleware(middlewares);
    }
  }
  setMiddleware(middlewares) {
    this.handler = createJsonRpcMiddlewareStack(middlewares, this.sender.sendAsync);
  }
  sendAsync(request, callback, chainId) {
    try {
      this.handler(request, callback, chainId);
    } catch (err) {
      callback(err, undefined);
    }
  }
}
const createJsonRpcMiddlewareStack = (middlewares, handler) => {
  if (middlewares.length === 0) return handler;
  const toMiddleware = v => {
    if (v.sendAsyncMiddleware) {
      return v.sendAsyncMiddleware;
    } else {
      return v;
    }
  };
  let chain;
  chain = toMiddleware(middlewares[middlewares.length - 1])(handler);
  for (let i = middlewares.length - 2; i >= 0; i--) {
    chain = toMiddleware(middlewares[i])(chain);
  }
  return chain;
};

function isJsonRpcProvider(cand) {
  return cand !== undefined && cand.send !== undefined && cand.constructor.defaultUrl !== undefined && cand.detectNetwork !== undefined && cand.getSigner !== undefined && cand.perform !== undefined;
}
function isJsonRpcHandler(cand) {
  return cand !== undefined && cand.sendAsync !== undefined;
}

let _nextId = 0;
class JsonRpcSender {
  constructor(provider, defaultChainId) {
    this.sendAsync = (request, callback, chainId) => {
      this.send(request.method, request.params, chainId || this.defaultChainId).then(r => {
        callback(undefined, {
          jsonrpc: '2.0',
          id: request.id,
          result: r
        });
      }).catch(e => {
        callback(e, undefined);
      });
    };
    this.defaultChainId = defaultChainId;
    if (isJsonRpcProvider(provider)) {
      // we can ignore defaultChainId for JsonRpcProviders as they are already chain-bound
      this.send = provider.send.bind(provider);
    } else if (isJsonRpcHandler(provider)) {
      this.send = (method, params, chainId) => {
        return new Promise((resolve, reject) => {
          provider.sendAsync({
            // TODO: really shouldn't have to set these here?
            jsonrpc: JsonRpcVersion,
            id: ++_nextId,
            method,
            params
          }, (error, response) => {
            if (error) {
              reject(error);
            } else if (response) {
              resolve(response.result);
            } else {
              resolve(undefined);
            }
          }, chainId || this.defaultChainId);
        });
      };
    } else {
      this.send = provider;
    }
    this.request = (request, chainId) => {
      return this.send(request.method, request.params, chainId);
    };
  }
}
class JsonRpcExternalProvider {
  constructor(provider) {
    this.sendAsync = (request, callback) => {
      this.provider.send(request.method, request.params).then(r => {
        callback(undefined, {
          jsonrpc: '2.0',
          id: request.id,
          result: r
        });
      }).catch(e => {
        callback(e, undefined);
      });
    };
    this.send = this.sendAsync;
    this.provider = provider;
  }
}

class AllowProvider {
  constructor(isAllowedFunc) {
    if (isAllowedFunc) {
      this.isAllowedFunc = isAllowedFunc;
    } else {
      this.isAllowedFunc = request => true;
    }
    this.sendAsyncMiddleware = allowProviderMiddleware(this.isAllowedFunc);
  }
  setIsAllowedFunc(fn) {
    this.isAllowedFunc = fn;
    this.sendAsyncMiddleware = allowProviderMiddleware(this.isAllowedFunc);
  }
}
const allowProviderMiddleware = isAllowed => next => {
  return (request, callback, chainId) => {
    // ensure precondition is met or do not allow the request to continue
    if (!isAllowed(request)) {
      throw new Error('allowProvider middleware precondition is unmet.');
    }

    // request is allowed. keep going..
    next(request, callback, chainId);
  };
};

class CachedProvider {
  // onUpdateCallback callback to be notified when cache values are set.

  // defaultChainId is used for default chain select with used with multi-chain provider

  constructor(options) {
    // cachableJsonRpcMethods which can be permanently cached for lifetime
    // of the provider.
    this.cachableJsonRpcMethods = ['net_version', 'eth_chainId', 'eth_accounts', 'sequence_getWalletContext', 'sequence_getNetworks'];
    // cachableJsonRpcMethodsByBlock which can be temporarily cached for a short
    // period of time, essentially by block time. As we support chains fast blocks,
    // we keep the values here cachable only for 1.5 seconds. This is still useful to
    // memoize the calls within app-code that calls out to fetch these values within
    // a short period of time.
    this.cachableJsonRpcMethodsByBlock = ['eth_call', 'eth_getCode'];
    this.cacheByBlockResetLock = false;
    this.sendAsyncMiddleware = next => {
      return (request, callback, chainId) => {
        // Respond early with cached result
        if (this.cachableJsonRpcMethods.includes(request.method) || this.cachableJsonRpcMethodsByBlock.includes(request.method)) {
          const key = this.cacheKey(request.method, request.params, chainId || this.defaultChainId);
          const result = this.getCacheValue(key);
          if (result && result !== '') {
            callback(undefined, {
              jsonrpc: '2.0',
              id: request.id,
              result: result
            });
            return;
          }
        }

        // Continue down the handler chain
        next(request, (error, response, chainId) => {
          // Store result in cache and continue
          if (this.cachableJsonRpcMethods.includes(request.method) || this.cachableJsonRpcMethodsByBlock.includes(request.method)) {
            if (response && response.result && this.shouldCacheResponse(request, response)) {
              // cache the value
              const key = this.cacheKey(request.method, request.params, chainId || this.defaultChainId);
              if (this.cachableJsonRpcMethods.includes(request.method)) {
                this.setCacheValue(key, response.result);
              } else {
                this.setCacheByBlockValue(key, response.result);
              }
            }
          }

          // Exec next handler
          callback(error, response);
        }, chainId || this.defaultChainId);
      };
    };
    this.cacheKey = (method, params, chainId) => {
      let key = '';
      if (chainId) {
        key = `${chainId}:${method}:`;
      } else {
        key = `:${method}:`;
      }
      if (!params || params.length === 0) {
        return key + '[]';
      }
      return key + JSON.stringify(params);
    };
    this.getCache = () => this.cache;
    this.setCache = cache => {
      this.cache = cache;
      if (this.onUpdateCallback) {
        this.onUpdateCallback();
      }
    };
    this.getCacheValue = key => {
      if (this.cache[key]) {
        return this.cache[key];
      }
      if (this.cacheByBlock[key]) {
        return this.cacheByBlock[key];
      }
      return undefined;
    };
    this.setCacheValue = (key, value) => {
      this.cache[key] = value;
      if (this.onUpdateCallback) {
        this.onUpdateCallback(key, value);
      }
    };
    this.setCacheByBlockValue = (key, value) => {
      this.cacheByBlock[key] = value;

      // clear the cacheByBlock once every X period of time
      if (!this.cacheByBlockResetLock) {
        this.cacheByBlockResetLock = true;
        setTimeout(() => {
          this.cacheByBlockResetLock = false;
          this.cacheByBlock = {};
        }, 1500); // 1.5 second cache lifetime
      }
    };
    this.shouldCacheResponse = (request, response) => {
      // skip if we do not have response result
      if (!response || !response.result) {
        return false;
      }

      // skip caching eth_getCode where resposne value is '0x' or empty
      if (request.method === 'eth_getCode' && response.result.length <= 2) {
        return false;
      }

      // all good -- signal to cache the result
      return true;
    };
    this.clearCache = () => {
      this.cache = {};
      this.cacheByBlock = {};
    };
    this.cache = {};
    this.cacheByBlock = {};
    this.defaultChainId = options == null ? void 0 : options.defaultChainId;
    if (!(options != null && options.blockCache)) {
      this.cachableJsonRpcMethodsByBlock = [];
    } else if ((options == null ? void 0 : options.blockCache) !== true) {
      this.cachableJsonRpcMethodsByBlock = options == null ? void 0 : options.blockCache;
    }
  }
  onUpdate(callback) {
    this.onUpdateCallback = callback;
  }
}

// EagerProvider will eagerly respond to a provider request from pre-initialized data values.
//
// This is useful for saving a few remote calls for responses we're already expecting when
// communicating to a specific network provider.
class EagerProvider {
  constructor(options) {
    this.sendAsyncMiddleware = next => {
      return (request, callback, chainId) => {
        const {
          id,
          method
        } = request;
        switch (method) {
          case 'net_version':
            if (this.options.chainId) {
              callback(undefined, {
                jsonrpc: '2.0',
                id: id,
                result: `${this.options.chainId}`
              });
              return;
            }
            break;
          case 'eth_chainId':
            if (this.options.chainId) {
              callback(undefined, {
                jsonrpc: '2.0',
                id: id,
                result: ethers.utils.hexlify(this.options.chainId)
              });
              return;
            }
            break;
          case 'eth_accounts':
            if (this.options.accountAddress) {
              callback(undefined, {
                jsonrpc: '2.0',
                id: id,
                result: [ethers.utils.getAddress(this.options.accountAddress)]
              });
              return;
            }
            break;
          case 'sequence_getWalletContext':
            if (this.options.walletContext) {
              callback(undefined, {
                jsonrpc: '2.0',
                id: id,
                result: this.options.walletContext
              });
              return;
            }
            break;
        }
        next(request, callback, chainId);
      };
    };
    this.options = options;
  }
}

const exceptionProviderMiddleware = next => {
  return (request, callback, chainId) => {
    next(request, (error, response) => {
      if (!error && response && response.error) {
        if (typeof response.error === 'string') {
          throw new Error(response.error);
        } else {
          throw new Error(response.error.message);
        }
      }
      callback(error, response);
    }, chainId);
  };
};

// TODO: rename to loggerMiddleware
const loggingProviderMiddleware = next => {
  return (request, callback, chainId) => {
    const chainIdLabel = chainId ? ` chainId:${chainId}` : '';
    logger.info(`[provider request]${chainIdLabel} id:${request.id} method:${request.method} params:`, request.params);
    next(request, (error, response) => {
      if (error) {
        logger.warn(`[provider response]${chainIdLabel} id:${request.id} method:${request.method} params:`, request.params, `error:`, error);
      } else {
        logger.info(`[provider response]${chainIdLabel} id:${request.id} method:${request.method} params:`, request.params, `response:`, response);
      }
      callback(error, response);
    }, chainId);
  };
};

const networkProviderMiddleware = getChainId => next => {
  return (request, callback, chainId) => {
    const networkChainId = getChainId(request);
    const {
      id,
      method
    } = request;
    switch (method) {
      case 'net_version':
        callback(undefined, {
          jsonrpc: '2.0',
          id: id,
          result: `${networkChainId}`
        });
        return;
      case 'eth_chainId':
        callback(undefined, {
          jsonrpc: '2.0',
          id: id,
          result: ethers.utils.hexlify(networkChainId)
        });
        return;
    }

    // request is allowed. keep going..
    next(request, callback, chainId);
  };
};

const SignerJsonRpcMethods = ['personal_sign', 'eth_sign', 'eth_signTypedData', 'eth_signTypedData_v4', 'eth_sendTransaction', 'eth_sendRawTransaction', 'sequence_sign',
// sequence-aware personal_sign
'sequence_signTypedData_v4',
// sequence-aware eth_signTypedData_v4

'sequence_getWalletContext', 'sequence_getWalletConfig', 'sequence_getWalletState', 'sequence_getNetworks', 'sequence_updateConfig', 'sequence_publishConfig', 'sequence_gasRefundOptions', 'sequence_getNonce', 'sequence_relay', 'eth_decrypt', 'eth_getEncryptionPublicKey', 'wallet_addEthereumChain', 'wallet_switchEthereumChain', 'wallet_registerOnboarding', 'wallet_watchAsset', 'wallet_scanQRCode'];
class SigningProvider {
  constructor(provider) {
    this.sendAsyncMiddleware = next => {
      return (request, callback, chainId) => {
        // Forward signing requests to the signing provider
        if (SignerJsonRpcMethods.includes(request.method)) {
          this.provider.sendAsync(request, callback, chainId);
          return;
        }

        // Continue to next handler
        next(request, callback, chainId);
      };
    };
    this.provider = provider;
  }
}

class PublicProvider {
  constructor(rpcUrl) {
    this.privateJsonRpcMethods = ['net_version', 'eth_chainId', 'eth_accounts', ...SignerJsonRpcMethods];
    this.sendAsyncMiddleware = next => {
      return (request, callback) => {
        // When provider is configured, send non-private methods to our local public provider
        if (this.provider && !this.privateJsonRpcMethods.includes(request.method)) {
          this.provider.send(request.method, request.params).then(r => {
            callback(undefined, {
              jsonrpc: '2.0',
              id: request.id,
              result: r
            });
          }).catch(e => callback(e));
          return;
        }

        // Continue to next handler
        logger.debug('[public-provider] sending request to signer window', request.method);
        next(request, callback);
      };
    };
    if (rpcUrl) {
      this.setRpcUrl(rpcUrl);
    }
  }
  getRpcUrl() {
    return this.rpcUrl;
  }
  setRpcUrl(rpcUrl) {
    if (!rpcUrl || rpcUrl === '') {
      this.rpcUrl = undefined;
      this.provider = undefined;
    } else {
      this.rpcUrl = rpcUrl;
      // TODO: maybe use @0xsequence/network JsonRpcProvider here instead,
      // which supports better caching.
      this.provider = new providers.JsonRpcProvider(rpcUrl);
    }
  }
}

class SingleflightMiddleware {
  constructor() {
    this.singleflightJsonRpcMethods = ['eth_chainId', 'net_version', 'eth_call', 'eth_getCode', 'eth_blockNumber', 'eth_getBalance', 'eth_getStorageAt', 'eth_getTransactionCount', 'eth_getBlockTransactionCountByHash', 'eth_getBlockTransactionCountByNumber', 'eth_getUncleCountByBlockHash', 'eth_getUncleCountByBlockNumber', 'eth_getBlockByHash', 'eth_getBlockByNumber', 'eth_getTransactionByHash', 'eth_getTransactionByBlockHashAndIndex', 'eth_getTransactionByBlockNumberAndIndex', 'eth_getTransactionReceipt', 'eth_getUncleByBlockHashAndIndex', 'eth_getUncleByBlockNumberAndIndex', 'eth_getLogs'];
    this.sendAsyncMiddleware = next => {
      return (request, callback, chainId) => {
        // continue to next handler if method isn't part of methods list
        if (!this.singleflightJsonRpcMethods.includes(request.method)) {
          next(request, callback, chainId);
          return;
        }
        const key = this.requestKey(request.method, request.params || [], chainId);
        if (!this.inflight[key]) {
          // first request -- init the empty list
          this.inflight[key] = [];
        } else {
          // already in-flight, add the callback to the list and return
          this.inflight[key].push({
            id: request.id,
            callback
          });
          return;
        }

        // Continue down the handler chain
        next(request, (error, response, chainId) => {
          // callback the original request
          callback(error, response);

          // callback all other requests of the same kind in queue, with the
          // same response result as from the first response.
          for (let i = 0; i < this.inflight[key].length; i++) {
            const sub = this.inflight[key][i];
            if (error) {
              sub.callback(error, response);
            } else if (response) {
              sub.callback(undefined, {
                jsonrpc: '2.0',
                id: sub.id,
                result: response.result
              });
            }
          }

          // clear request key
          delete this.inflight[key];
        }, chainId);
      };
    };
    this.requestKey = (method, params, chainId) => {
      let key = '';
      if (chainId) {
        key = `${chainId}:${method}:`;
      } else {
        key = `:${method}:`;
      }
      if (!params || params.length === 0) {
        return key + '[]';
      }
      return key + JSON.stringify(params);
    };
    this.inflight = {};
  }
}

// JsonRpcProvider with a middleware stack. By default it will use a simple caching middleware.
class JsonRpcProvider extends ethers.providers.JsonRpcProvider {
  constructor(url, options) {
    super(url, options == null ? void 0 : options.chainId);
    this.send = (method, params) => {
      return this._sender.send(method, params);
    };
    this.fetch = (method, params) => {
      const request = {
        method: method,
        params: params,
        id: this._nextId++,
        jsonrpc: '2.0'
      };
      const result = ethers.utils.fetchJson(this.connection, JSON.stringify(request), getResult).then(result => {
        return result;
      }, error => {
        throw error;
      });
      return result;
    };
    const chainId = options == null ? void 0 : options.chainId;
    const middlewares = options == null ? void 0 : options.middlewares;
    const blockCache = options == null ? void 0 : options.blockCache;
    this._chainId = chainId;

    // NOTE: it will either use the middleware stack passed to the constructor
    // or it will use the default caching middleware provider. It does not concat them,
    // so if you set middlewares, make sure you set the caching middleware yourself if you'd
    // like to keep using it.
    const router = new JsonRpcRouter(middlewares != null ? middlewares : [
    // loggingProviderMiddleware,
    new EagerProvider({
      chainId
    }), new SingleflightMiddleware(), new CachedProvider({
      defaultChainId: chainId,
      blockCache: blockCache
    })], new JsonRpcSender(this.fetch, chainId));
    this._sender = new JsonRpcSender(router, chainId);
  }
  async getNetwork() {
    const chainId = this._chainId;
    if (chainId) {
      const network = networks[chainId];
      const name = (network == null ? void 0 : network.name) || '';
      const ensAddress = network == null ? void 0 : network.ensAddress;
      return {
        name: name,
        chainId: chainId,
        ensAddress: ensAddress
      };
    } else {
      const chainIdHex = await this.send('eth_chainId', []);
      this._chainId = ethers.BigNumber.from(chainIdHex).toNumber();
      return this.getNetwork();
    }
  }
}
function getResult(payload) {
  if (payload.error) {
    // @TODO: not any
    const error = new Error(payload.error.message);
    error.code = payload.error.code;
    error.data = payload.error.data;
    throw error;
  }
  return payload.result;
}

export { AllowProvider, CachedProvider, EagerProvider, JsonRpcExternalProvider, JsonRpcProvider, JsonRpcRouter, JsonRpcSender, JsonRpcVersion, PublicProvider, SigningProvider, SingleflightMiddleware, allNetworks, allowProviderMiddleware, checkNetworkConfig, createJsonRpcMiddlewareStack, ensureUniqueNetworks, ensureValidNetworks, exceptionProviderMiddleware, findNetworkConfig, findSupportedNetwork, getChainId, indexerURL, isJsonRpcHandler, isJsonRpcProvider, isNetworkConfig, isValidNetworkConfig, loggingProviderMiddleware, maybeChainId, networkProviderMiddleware, networksIndex, nodesURL, relayerURL, sortNetworks, stringTemplate, toChainIdNumber, updateNetworkConfig, validateAndSortNetworks };
