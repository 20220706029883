import { useDebounce } from "use-debounce";

import { useOptionPricesQuery } from "../../queries/hooks/useOptionPricesQuery";

import { usePairPrices } from "./usePairPrices";

import type Big from "big.js";

export const useOptionPrices = (
  pairId: string,
  positionSize: Big,
  expiry: number
) => {
  const [debouncedPositionSize, debouncedState] = useDebounce(
    positionSize,
    300,
    {
      equalityFn: (a, b) => a.toString() === b.toString(),
    }
  );

  const pairPrices = usePairPrices(pairId);

  const optionPrices = useOptionPricesQuery(
    pairId,
    debouncedPositionSize,
    pairPrices,
    expiry
  );

  // return undefined when positionSize has changed
  if (debouncedState.isPending()) {
    return undefined;
  }

  return optionPrices.data;
};
