import { getZero, toBig } from "../../shared/helpers/bigjs";
import { getFormattedTokenAmount } from "../../shared/helpers/formatters";
import {
  InfoRow,
  InfoTitle,
  InfoValue,
} from "../../shared/modal/styles/ModalInfo";
import { useTradePanelOptionPrice } from "../hooks/useTradePanelOptionPrice";
import { useTradePanelState } from "../stores/useTradePanelState";

export const PositionSizeInfo = () => {
  const { positionSide, pairId, quoteTokenInputState, expiry } =
    useTradePanelState();

  const positionSize = quoteTokenInputState.inputValueBig;

  const optionPrice = useTradePanelOptionPrice(
    positionSide,
    pairId,
    quoteTokenInputState,
    expiry
  );

  const formattedNotionalSize = getFormattedTokenAmount(
    positionSize.gt(0) && optionPrice && toBig(optionPrice).gt(0)
      ? positionSize.div(optionPrice)
      : getZero()
  );

  return (
    <InfoRow>
      <InfoTitle>Notional Size</InfoTitle>
      <InfoValue>
        {formattedNotionalSize} {pairId.split("-")[0]}
      </InfoValue>
    </InfoRow>
  );
};
