/* eslint unicorn/numeric-separators-style: 0 */
import { Button, ButtonGroup } from "@chakra-ui/react";

import { useTradePanelState } from "../stores/useTradePanelState";

export const Expiry = () => {
  const { expiry, setExpiry } = useTradePanelState();

  return (
    <ButtonGroup size="sm">
      <Button
        onClick={() => {
          setExpiry(86400);
        }}
        variant={expiry === 86400 ? "solid" : "outline"}
        w="full"
      >
        1d
      </Button>
      <Button
        onClick={() => {
          setExpiry(2 * 86400);
        }}
        variant={expiry === 2 * 86400 ? "solid" : "outline"}
        w="full"
      >
        2d
      </Button>
      <Button
        onClick={() => {
          setExpiry(3 * 86400);
        }}
        variant={expiry === 3 * 86400 ? "solid" : "outline"}
        w="full"
      >
        3d
      </Button>
      <Button
        onClick={() => {
          setExpiry(4 * 86400);
        }}
        variant={expiry === 4 * 86400 ? "solid" : "outline"}
        w="full"
      >
        4d
      </Button>
    </ButtonGroup>
  );
};
