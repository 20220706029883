import { toTokenAmount } from "../../input-card/helpers/tokenAmount";
import { getPairConfig } from "../../pair/helpers/getPairConfig";
import { queryClient } from "../../shared/constants/queryClient";
import { getExp, toBig } from "../../shared/helpers/bigjs";
import { IGoodEntryPositionManager__factory as PositionManagerFactory } from "../../smart-contracts/types";
import { getProvider } from "../../web3/helpers/getProvider";
import { getTokenQueryOptions } from "../query-options-getters/getTokenQueryOptions";

import type { OptionBorrowRates } from "../types/OptionBorrowRates";
import type { PairPrices } from "../types/PairPrices";
import type Big from "big.js";

const defaultOptionBorrowRates = {
  lowerOptionPrice: null,
  upperOptionPrice: null,
  lowerOptionHourlyBorrowRate: null,
  upperOptionHourlyBorrowRate: null,
};

export const optionBorrowRatesFetcher = async (
  pairId: string,
  positionSize: Big,
  pairPrices: PairPrices | undefined,
  expiry: number
): Promise<OptionBorrowRates> => {
  if (!pairPrices) {
    return defaultOptionBorrowRates;
  }
  let posSize = positionSize;
  if (positionSize.lte(0)) {
    posSize = toBig(1);
  }

  const {
    chainId,
    addresses: {
      baseToken: baseTokenAddress,
      quoteToken: quoteTokenAddress,
      positionManager,
    },
  } = getPairConfig(pairId);

  const provider = getProvider(chainId);

  const positionManagerContract = PositionManagerFactory.connect(
    positionManager,
    provider
  );

  const { baseTokenPrice, lowerStrikePrice, upperStrikePrice } = pairPrices;

  const [baseToken, quoteToken] = await Promise.all([
    queryClient.ensureQueryData(
      getTokenQueryOptions(chainId, baseTokenAddress)
    ),
    queryClient.ensureQueryData(
      getTokenQueryOptions(chainId, quoteTokenAddress)
    ),
  ]);

  const baseTokenNotionalAmount = toTokenAmount(
    posSize.div(baseTokenPrice),
    baseToken
  );
  const quoteTokenNotionalAmount = toTokenAmount(posSize, quoteToken);

  const priceDivisor = getExp(8);
  const [rawLowerStrikePrice, rawUpperStrikePrice] = [
    lowerStrikePrice,
    upperStrikePrice,
  ].map((value) => toBig(value).mul(priceDivisor));

  const [rawLowerOptionPrice, rawUpperOptionPrice] = await Promise.all([
    positionManagerContract.getOptionPrice(
      false,
      rawLowerStrikePrice.toString(),
      quoteTokenNotionalAmount.toString(),
      expiry
    ),
    positionManagerContract.getOptionPrice(
      true,
      rawUpperStrikePrice.toString(),
      baseTokenNotionalAmount.toString(),
      expiry
    ),
  ]);

  const [lowerOptionPrice, upperOptionPrice] = [
    rawLowerOptionPrice,
    rawUpperOptionPrice,
  ].map((value) => toBig(value).div(priceDivisor).toNumber());

  return {
    lowerOptionPrice,
    upperOptionPrice,
    lowerOptionHourlyBorrowRate: null,
    upperOptionHourlyBorrowRate: null,
  };
};
