/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IGoodEntryVaultMigrationManager,
  IGoodEntryVaultMigrationManagerInterface,
} from "../IGoodEntryVaultMigrationManager";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "weth",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "sourceVault",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "targetVault",
        type: "address",
      },
    ],
    name: "migrate",
    outputs: [
      {
        internalType: "uint256",
        name: "liquidity",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

export class IGoodEntryVaultMigrationManager__factory {
  static readonly abi = _abi;
  static createInterface(): IGoodEntryVaultMigrationManagerInterface {
    return new utils.Interface(
      _abi
    ) as IGoodEntryVaultMigrationManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IGoodEntryVaultMigrationManager {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IGoodEntryVaultMigrationManager;
  }
}
