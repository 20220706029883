import { Button, ButtonGroup } from "@chakra-ui/react";

import type { OneSideSwitcherProps } from "../types/OneSideSwitcherProps";
import type { FC } from "react";

export const OneSideSwitcher: FC<OneSideSwitcherProps> = ({
  title1,
  icon1,
}) => (
  <ButtonGroup isAttached size="sm">
    <Button leftIcon={icon1} variant="short" w="full">
      {title1}
    </Button>
  </ButtonGroup>
);
