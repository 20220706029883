import { useOptionPrices } from "../../protected-perps-page/hooks/useOptionPrices";
import { isPositionSideLong } from "../helpers/isPositionSideLong";

import type { TradePanelState } from "../types/TradePanelState";

export const useTradePanelOptionPrice = (
  positionSide: TradePanelState["positionSide"],
  pairId: TradePanelState["pairId"],
  quoteTokenInputState: TradePanelState["quoteTokenInputState"],
  expiry: TradePanelState["expiry"]
) => {
  const positionSize = quoteTokenInputState.inputValueBig;

  const { lowerOptionPrice, upperOptionPrice } =
    useOptionPrices(pairId, positionSize, expiry) ?? {};

  const isLong = isPositionSideLong(positionSide);

  return isLong ? lowerOptionPrice : upperOptionPrice;
};
