import { InputContainer, InputTitle } from "../../input-card/styles/InputCard";
import { getFormattedPrice } from "../../shared/helpers/formatters";
import { useTradePanelOptionPrice } from "../hooks/useTradePanelOptionPrice";
import { useTradePanelStrikePrice } from "../hooks/useTradePanelStrikePrice";
import { useTradePanelState } from "../stores/useTradePanelState";
import { Container, Content, Value } from "../styles/StrikePrice";

export const StrikePrice = () => {
  const { positionSide, pairId, quoteTokenInputState, expiry } =
    useTradePanelState();

  const strikePrice = useTradePanelStrikePrice(positionSide, pairId);
  const optionPrice = useTradePanelOptionPrice(
    positionSide,
    pairId,
    quoteTokenInputState,
    expiry
  );

  const formattedStrikePrice = getFormattedPrice(strikePrice);
  const formattedOptionPrice = getFormattedPrice(optionPrice);

  return (
    <InputContainer>
      <Content>
        <InputTitle>Activation Price</InputTitle>
        <InputTitle>Unit Price</InputTitle>
      </Content>
      <Container>
        <Content>
          <Value>{formattedStrikePrice}</Value>
          <Value>{formattedOptionPrice}</Value>
        </Content>
      </Container>
    </InputContainer>
  );
};
