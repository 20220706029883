/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IGoodEntryStrikePriceManager,
  IGoodEntryStrikePriceManagerInterface,
} from "../IGoodEntryStrikePriceManager";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    name: "getStrikeAbove",
    outputs: [
      {
        internalType: "uint256",
        name: "strike",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    name: "getStrikeBelow",
    outputs: [
      {
        internalType: "uint256",
        name: "strike",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    name: "getStrikeSpacing",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    name: "getStrikeStrictlyAbove",
    outputs: [
      {
        internalType: "uint256",
        name: "strike",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    name: "getStrikeStrictlyBelow",
    outputs: [
      {
        internalType: "uint256",
        name: "strike",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "strike",
        type: "uint256",
      },
    ],
    name: "isValidStrike",
    outputs: [
      {
        internalType: "bool",
        name: "isValid",
        type: "bool",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class IGoodEntryStrikePriceManager__factory {
  static readonly abi = _abi;
  static createInterface(): IGoodEntryStrikePriceManagerInterface {
    return new utils.Interface(_abi) as IGoodEntryStrikePriceManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IGoodEntryStrikePriceManager {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IGoodEntryStrikePriceManager;
  }
}
